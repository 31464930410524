@import "~web-styles/src/variables";
.MainContainer {
}

.page-containe{
    @include media-breakpoint-up(md) {
    position: relative;
    min-height:  calc(100vh - 18rem);
    }
    .Button-ds  {
        border-radius: var(--button-radius);
    }
    .Button-ds--withHover::after  {
        border-radius: var(--button-radius);
    }
    @include media-breakpoint-down(sm) {
        .serviceSelectionLayout, .mainLogiContainer {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
            .inner-page {
                flex-grow: 1;
            }
        }
    }
}