@import "~web-styles/src/variables";
@import "~web-styles/src/mixins";
@import "~web-styles/src/grid";

.Header-custom {
  @include media-breakpoint-up(md) {
    // position: sticky; //changed as per the ticket ES-19516
    top: 0;
    background: #ffffff;
    z-index: 1000;
  }

  .Grid {
    max-width: none  !important//included as per the ticket ES-19516
  }

  .Grid {
    @include centerContent;

    .fullGrid {
      @include ultaGrid;

      @include media-breakpoint-down(md) {
        margin-left: 0.6rem;
        margin-right: 0.6rem;
      }

      .spanMinusTwo {
        grid-column: 2 / span 10;

        @include media-breakpoint-down(md) {
          grid-column: 2 / span 4;
        }
      }
    }

    .component {
      margin: 1px 0;
      min-height: 2rem;
      padding-top: 0.4rem;
      text-align: center;
    }

    .component:nth-child(3) {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-self: center; 
      @include media-breakpoint-down(md) {
      margin-right: .5rem;
      }     
    }

    .component:nth-child(1) {
      width: 7.5rem;
      height: 2rem;
      display: inline-block;
      align-self: center;
    }
  }

  .Image__Responsive {
    @include media-breakpoint-up(md) {
      height: auto;
      min-width: 7rem;
    }
  }

  margin: 0 auto;
  padding: 10px 0px;

  @include media-breakpoint-down(lg) {
    padding: 10px 0px 0px 0px;
  }

  position: relative;

  .Link_Huge {
    background: none;
    font-size: 0.815rem;
  }

  .Header__nav--menu {
    .Link_Huge {
      display: flex;
    }
  }

  .Header-Nav {
    padding-top: 1.3rem;

    @include media-breakpoint-down(lg) {
      padding-bottom: 0rem;
      padding-top: 0.8rem;
    }
  }
  
  a {
    color: black;
    cursor: pointer;
    text-align: center;
    height: 0.75rem;
    width: 5.25rem;
    color: #000000;
    opacity: 1;
    font-size: 0.6rem;
    font-weight: 500;
    letter-spacing: 0.016rem;
    line-height: 0.75rem;
    text-decoration: none;
  }
  a img,span{
    vertical-align: middle;
  }
  a span{
    margin-left: 0.3rem;
    }
   

  .modal__container {
    height: 100%;
    width: 100%;
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }

  &__nav {
    flex: 0 0 auto;
    padding: 0 8px;
    text-align: center;
    vertical-align: middle;
  }

  svg {
    display: block;
    height: 28px;
    margin: 0 auto;
    padding-bottom: 4px;
    width: 24px;
  }

  &__logo span {
    display: none;
  }

  &__logo {
    svg {
      path {
        fill: map-get($neutral, "600");
      }

      height: 40px;
      min-width: 125px;
    }

    flex: 2 1 auto;
  }

  li+li {
    margin-left: 0;
  }

  nav {
    display: block;
  }

  .SearchBar {
    width: 100%;

    svg {
      display: inline;
      padding-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      width: 200px;
    }
  }
}

/*   Flyout   */

.Flyout {
  display: none;
  width: 34vw !important;
  padding: 2rem 2rem 2rem 2.5rem;
  @include media-breakpoint-down(md) {
    width: auto !important;
  }
  @include media-breakpoint-down(sm) {
    width: 85vw !important;
  }
}
.flyout-container {
  display: flex;
  flex-wrap: nowrap;
}
.flyout-container > div {
  width: 100%;
  margin: 0rem;
  text-align: left;
}
.flyout-container > div:first-child {
  width: 4.5rem;
  margin-top: 0.6rem;
  text-align: left;
}
.flyout-container > div:nth-child(2) {
  width: 22rem;
  margin-top: 0.5rem;
}
.gotoDashboard-text {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: 1.5rem;
  background-color: transparent !important;
  color: #000000 !important;
  text-transform: none !important;
  padding: 0;
}
.log-out-btn .Button-ds {
  margin: 0rem;
  cursor: pointer;
  background-color: transparent;
  color: #000000;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1.3px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #000;
  padding: 1rem 2rem;
}

.dshOutline {
  outline: none !important;
}
.bag {
  position:relative;
  right: .3rem;
  margin-left: .8rem;
}
.bag:disabled {
  color: #999;
  opacity: 0.2;
  cursor: not-allowed;
}
.servicesText {
  position: absolute;
  // top: .0225rem;
  left: .23rem;
  top: 10%;
  right: 0.23rem;
  font-size: 0.675rem;
  font-weight: 600;
  letter-spacing: 0.07rem;
  line-height: 1.1rem;
  color: #000000;}
.circle{
  position: absolute;
  border: 0.125rem solid white;
  padding: 0.3rem  0.525rem; 
  background: #4D82FF;
  width: 0.0625rem;
  height: 1.25rem;
  border-radius: 100px;
  transform: scale(0.5, 0.5);
  top: .20rem;
  left: 45%;
}
.Flyout__close:focus {
  outline: 2px solid #4D82FF; 
}
