.header {
    background: var(--pal-colors-default-background-notification-strong, #CCD8FF) !important;

    .banner-large {
        height: 72px;
        display: flex;
        justify-content: center;
        align-items: center;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 900px;
            height: 20px;
            //gap: 16px;
        }
    }

    .banner-small {
        height: 72px; 
        display: flex;
        justify-content: center;
        align-items: center;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 631px;
            height: 20px;
            //gap: 8px;
        }
    }


    .title {
        font-family: Circular Std;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-align: center;
        color: var(--pal-colors-default-content-default, #000000);
    }

    .subtitle {
        font-family: Circular Std;
        font-size: 16px;
        font-weight: 450;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-align: center;
        color: var(--pal-colors-default-content-default, #000000);
    }
}