@import "~web-styles/src/variables";
@import "~web-styles/src/grid";



.Footer {
  border-top: 0.25rem solid map-get($magenta, "400");
  background-color: map-get($neutral, "25");
  
  @include media-breakpoint-up(md) {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: -7.25rem;
  }
    
  @include media-breakpoint-up(xl) {
    padding-bottom: 2rem;
  }

  @include media-breakpoint-up(lg) {
    .right-list {
      .Text-ds--body-1 {
        font-weight: 400;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .right-list {
      padding-top: 1rem;
    }
  }

  

  &__wrapper {
    margin: 0 auto;
    max-width: 90rem;
    
  }

  &__footerTagline {
    grid-column: span 12;
    margin-bottom: 3.0625rem;
    margin-left: 2.5rem;
    margin-top: 2.5rem;
    width: 19.813rem;

    @include media-breakpoint-down(lg) {
      margin-bottom: 2.6825rem;
    }

    @include media-breakpoint-down(md) {
      grid-column: 2/3;
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-only(md) {
      margin-left: 18.5%;
    }

    @include media-breakpoint-down(sm) {
      grid-column: span 6;
      width: 16.25rem;
    }
  }

  &__bottomWrapper {
    align-items: center;
    display: flex;
    height: 3rem;
    justify-content: space-between;
    padding: 0 2.5rem;

    @include media-breakpoint-up(lg) {
      margin-top: 1rem;
    }

    @include media-breakpoint-down(md) {
      margin-top: 0rem;
      padding: 0 1.5rem;
    }

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      height: auto;
      padding: 1.5rem 0;
    }

    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
     

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        flex-direction: row;
      }

      li {
        margin-left: 1.5rem;

        .Link_Huge {
          color: map-get($neutral, "700");
        }

        @include media-breakpoint-down(sm) {
          margin-left: 0;
        }

        &:nth-child(1) {
          @include media-breakpoint-down(sm) {
            display: block;
            // margin-bottom: 1rem;
            // margin-top: 1rem;
            order: 1;
            // width: 100%;
          }
        }

        &:nth-child(2) {
          @include media-breakpoint-down(sm) {
            order: 3;
            padding-left: 1.5rem;
          }
        }

        &:nth-child(3) {
          @include media-breakpoint-down(sm) {
            order: 2;
          }
        }
      }
    }
    
  }
  
  &__itemsAccordion {
    .FooterNavLinkGroup {
      @include media-breakpoint-down(md) {
        width: 100%;
      }

      ul {
        @include media-breakpoint-down(md) {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: 10.188rem;
        }

        li {
          @include media-breakpoint-down(md) {
            margin-bottom: 0;
            padding: 0.438rem;
          }
        }
      }
    }
  }

  .SignUpNews {
    @include media-breakpoint-down(lg) {
      margin-top: 0.825rem;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 2.5rem;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
