.modal-blocked{
  position: static !important;
  overflow: auto !important;  
}
.message-dialog-flyout {
  width: 20.5rem;
  margin: 1rem;
}

.flyoutModal-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .flyoutModal-container > div {
    width: 100%;
    margin: 0rem;
    text-align: left;
  }
  .flyoutModal-container > div:first-child {
    width: 7.5rem;
    text-align: center;
  }
  .flyoutModal-container > div:nth-child(2) {
    width: 5rem;
    text-align: center;
    margin-left: 3rem;
  }
  .continue_button {
    .Button-ds {
      font-size: 0.8rem;
      padding: 0.75rem 1.5rem;
      height: auto !important;
    }
    text-align: center;
    cursor: pointer;
  }
    .message-dialog-flyout{
      button {
        margin-right: 1rem;
      }
  
      p {
        margin: 1.5rem 2rem 0rem 0rem !important;
      }
    }
.Button-ds {
  border-radius: var(--button-radius);
}